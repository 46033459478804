<template>
    <modal ref="modalRechazarAnuncio" titulo="" tamano="modal-lg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-11 mt-3">
                    <vueper-slides class="vueperslides--1" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="true">
                        <template #arrow-right>
                            <div class="bg-white wh-32px rounded-circle d-middle-center text-black">
                                <i class="icon-right-open" />
                            </div>
                        </template>
                        <template #arrow-left>
                            <div class="bg-white wh-32px rounded-circle d-middle-center text-black">
                                <i class="icon-left-open" />
                            </div>
                        </template>
                        <vueper-slide v-for="(slide, i) in slider.data" :key="i" :image="slide.imagen"> </vueper-slide>
                    </vueper-slides>

                    <vueper-slides class="no-shadow vueperslides--2 mt-3 mb-0" ref="vueperslides2" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })" :visible-slides="5" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="true">
                        <template #arrow-right>
                            <i class="icon-right-open" />
                        </template>
                        <template #arrow-left>
                            <i class="icon-left-open" />
                        </template>
                        <vueper-slide v-for="(slide, i) in slider.data" :key="i" :image="slide.imagen" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)"></vueper-slide>
                    </vueper-slides>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
             slider:{
                data: [
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                ]
            },
        }
    },
    methods:{
        toggle(){
            this.$refs.modalRechazarAnuncio.toggle()
        },
        guardarValores(){
            this.$refs.modalRechazarAnuncio.toggle()
        },
        setImages(images){
          this.slider.data = images
        }
    }
}
</script>
<style lang="scss" scoped>

</style>